<template>
  <div class="back-button__container pointer" @click="nav">
    <div class="back-button__arrow-block">
      <div class="back-button__arrow-box">
        <i class="arrow left"></i>
      </div>
    </div>
    <span>Back</span>
  </div>
</template>

<script>
export default {
  name: 'BackButton',
  props: ['backLink'],
  methods: {
    nav() {
      if (this.backLink === undefined) return;
      const link = this.backLink || '/';
      this.$router.push(link);
    }
  }
};
</script>

<style lang="scss" scoped>
.back-button {
  &__container {
    margin: 10px 10vw;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    display: flex;
    align-items: center;

    color: #8a8a8a;
    @media (min-width: 768px) and (max-width: 1090px) {
      margin: 10px 10vw !important;
    }

    @media (max-width: 767px) {
      margin-left: 10px;
    }

    span {
      margin-left: 12.79px;
      font-size: 24px;
      line-height: 28px;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 28px;
      display: flex;
      align-items: center;

      color: #8a8a8a;
    }
  }
  &__arrow-box {
    width: 16.04px;
    height: 16.04px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__arrow-block {
    width: 45.21px;
    height: 40.83px;
    border: 1px solid #e5ecf2;
    box-sizing: border-box;
    border-radius: 5px;
    padding-left: 14.58px;
    padding-top: 13.12px;

    .arrow {
      border: solid #8a8a8a;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 4.18px;
    }

    .left {
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }
  }
}
</style>
