<template>
  <div class="step-process">
    <div v-for="step of steps" :key="step.number">
      <span
        :class="[
          'step',
          { active: step.number == active, last: step.number == 3 }
        ]"
      >
        <span class="number" v-if="step.number >= active">
          {{ step.number }}
        </span>
        <span v-else class="number">
          <span class="check-mark" />
        </span>
        <span class="name">{{ step.name }}</span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepProcess',
  props: ['active'],
  data: () => ({
    steps: [
      { number: 1, name: 'Input' },
      { number: 2, name: 'Process' },
      { number: 3, name: 'Results' }
    ]
  })
};
</script>

<style lang="scss" scoped>
.step-process {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 41px;

  .step {
    display: flex;
    margin-right: 40px;

    &.last {
      margin-right: 0;
    }

    .check-mark {
      display: flex;
      align-items: center;
      justify-content: center;
      background: url('../assets/check-mark.svg') no-repeat center center !important;
      width: 14px;
      height: 14px;
    }

    .number {
      width: 40px;
      height: 40px;

      background: #e5ecf2;
      border-radius: 7px;
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      display: flex;
      align-items: center;
      justify-content: center;

      color: #8a8a8a;
    }

    .name {
      margin-left: 8px;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      display: flex;
      align-items: center;

      color: #8a8a8a;

      @media (max-width: 480px) {
        display: none;
      }
    }

    &.active {
      .number {
        background: var(--kern-blue);
        color: #ffffff;
      }

      .name {
        font-weight: bold;
        color: var(--kern-blue);
      }
    }
  }
}
</style>
