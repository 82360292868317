<template>
  <div class="output-page">
    <StepProcess :active="3" />
    <div class="output-page__container" v-if="data !== null">
      <div class="info">
         <span v-if="data.status !== 'Failed'">
          <span class="job-id">Job# {{ data.id }}</span><br>
          Your {{ data.type === 'encode' ? 'encoding' : 'decoding' }} job was successfully completed.
        </span>
        <span v-else>
          <span class="job-id">Job# {{ data.id }}</span><br>
          Your {{ data.type === 'encode' ? 'encoding' : 'decoding' }} job has failed.
        </span>
      </div>
      <div class="output-page__result">
        <span class="output-page__title-secondary">Input:</span>
        <div
          v-if="data.status !== 'Failed' && !isTypeFile"
          ref="textarea"
          class="textarea"
          contenteditable="true"
        >
          <template v-if="data.type === 'encode'">
            {{ data.original_input_text }}
          </template>
          <template v-else>
            {{ input_text }}
          </template>
        </div>
        <a
          v-if="data.status !== 'Failed' && isTypeFile"
          :href="data.original_input_file_url"
          class="output-page__file-info-wrapper"
          @click.prevent="saveFile"
        >
          <span :class="['output-page__file-icon', this.getFileIcon]"></span>
          <span class="output-page__file-name">{{ data.original_input_filename }}</span>
        </a>
        <div class="output-page__divider"></div>
        <span class="output-page__title-secondary">Output:</span>
        <div
          v-if="data.status !== 'Failed'"
          ref="textarea"
          class="textarea"
          contenteditable="true"
        >
          <template v-if="data.type === 'encode'">
            {{ data.text }}
          </template>
          <template v-else-if="data.type === 'decode' && checkFileType(data.output_file_url) === 'docx'">
            Please download output and open with Microsoft Word
          </template>
          <template v-else-if="data.type === 'decode' && checkFileType(data.output_file_url) === 'txt'">
            {{ data.text }}
          </template>
        </div>
        <div class="actions">
          <v-btn class="black" v-if="data.status !== 'Failed'">
            <a
              class="custom-button"
              target="_blank"
              download
              :href="outputFileUrl"
            >
              download output
            </a>
          </v-btn>

          <v-btn
            v-if="data.type !== 'decode' && data.status !== 'Failed'"
            v-tooltip.bottom-start="msgCheckPricingTooltip"
            class="black"
          >
            <span class="text">check pricing </span>
          </v-btn>
        </div>
      </div>
    </div>
    <div @click="back"><BackButton /></div>
  </div>
</template>

<script>
import StepProcess from '@/components/StepProcess';
import BackButton from '@/components/BackButton';
import {bus} from '../bus.js';
import filesize from '../../node_modules/filesize/lib/filesize.es6.js';
import {mapActions} from 'vuex';

const VUE_APP_URL = process.env.VUE_APP_URL || '';

export default {
  name: 'OutputPage',
  components: {
    StepProcess,
    BackButton
  },
  data() {
    return {
      data: null,
      input_text: '',
    };
  },
  computed: {
    outputFileUrl() {
      const url =
        bus.data?.output_file_url || this.data?.output_file_url || null;
      return url;
    },
    msgCheckPricingTooltip() {
      const price = bus.data?.price || this.data?.price || '---';
      bus.data?.price || this.data.price, '---', 'ff';
      return `<div class="tooltip-html__price">Cost estimate: ${price}</div>`;
    },
    isTypeFile() {
      return this.data.original_input_filename
    },
    getFileIcon() {
      if (this.data.original_input_filename?.includes('.txt')) {
        return 'output-page__file-icon--txt'
      } else if (this.data.original_input_filename?.includes('.doc')) {
        return 'output-page__file-icon--doc'
      }
      return ''
    }
  },
  async created() {
    if (bus.data === undefined || bus.data.id !== this.$route.query.job_id) {
      const jobId = this.$route.query.hash || this.$route.query.job_id;
      await this.getJobById(jobId);
    } else if (this.$route.query.job_id) {
      const jobId = this.$route.query.job_id;
      await this.getJobById(jobId);
      const size = bus?.data?.output_file_size || 0;
      this.setProcessType(bus.data.type);
      this.data = {
        ...bus.data,
        fileSize: filesize(size)
      };
    }
  },
  methods: {
    ...mapActions(['setProcessType']),
    async getJobById(jobId) {
      const response = await this.axios.get(
        VUE_APP_URL + `/api/v1/jobs/${jobId}`
      );

      if (response.data.status === 'Failed') {
        const size = response?.data?.input_file_size || 0;
        const _data = {
          ...response.data,
          fileSize: filesize(size)
        };
        this.data = _data;
        this.setProcessType(_data.type);
      } else {
        const size = response?.data?.output_file_size || 0;
        const _data = {
          ...response.data,
          fileSize: filesize(size)
        };

        this.setProcessType(_data.type);
        this.axios
          .get(_data.output_file_url, { responseType: 'blob' })
          .then(file => {
            let reader = new FileReader();
            reader.onload = () => {
              const text = reader.result;

              _data.text = text;
              this.data = _data;
              this.getInputFileContent()
            };
            reader.readAsText(file.data);
          })
          .catch((e) => {
            console.error(e)
            this.data = _data;
          });
      }
    },
    getInputFileContent() {
      const inputUrl = this.data?.original_input_file_url
      if (inputUrl && this.data.type === 'decode') {
        this.axios
          .get(inputUrl, { responseType: 'blob' })
          .then(file => {
            const reader = new FileReader();
            reader.onload = () => {
              this.input_text = reader.result;
            };
            reader.readAsText(file.data);
          })
          .catch((e) => {
            console.error(e)
          });
      }
    },
    back() {
      if (bus.data !== undefined) {
        this.$router.push('/');
      } else {
        const querySearch = this.$route.params.search || '';
        if (querySearch.length) {
          this.$router.push({
            name: 'JobLookUp',
            query: { search: querySearch }
          });
        } else {
          this.$router.push({ name: 'JobLookUp' });
        }
      }
    },
    saveFile() {
      const url = this.data.original_input_file_url
      if (!url) return
      this.axios
        .get(url, { responseType: 'blob' })
        .then(response => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(response.data);
          link.download = this.data.original_input_filename;
          link.click();
        })
        .catch((e) => {
          console.error(e)
        });
    },
    checkFileType(filename) {
      if (filename.includes('.docx')) {
        return 'docx'
      }
      if (filename.includes('.txt')) {
        return 'txt'
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.output-page {
  margin-bottom: 74px;
  &__container {
    border: 1px solid #d4d4d4;
    box-sizing: border-box;
    border-radius: 10px;

    height: 100%;

    margin: 0 10vw;
    padding: 50px 70px;
    min-width: 460px;

    @media (max-width: 767px) {
      min-width: unset;
      margin: 0 10px;
      padding: 60px 20px 82px;
    }

    .info {
      margin-top: 71px;
      margin-bottom: 55px;
      text-align: center;

      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 26px;
      color: #8a8a8a;

      .job-id {
        font-weight: bold;
        color: var(--kern-blue);
      }

      @media (min-width: 768px) and (max-width: 1089px) {
        margin-bottom: 61px;
        margin-top: 23px;
      }
    }
    .textarea {
      height: 155px;
      margin: auto;
      background: #f2f2f6;
      border-radius: 4px;
      padding: 18px 53px 7px 28px;
      resize: none;

      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      white-space: pre-wrap;
      color: #8a8a8a;

      width: 100%;
      max-height: 155px;
      overflow: scroll;

      @media (max-width: 767px) {
        padding: 23px 23px;
      }
    }
    .actions {
      display: flex;

      margin-top: 51px;
      flex-wrap: wrap;

      @media (min-width: 768px) and (max-width: 1089px) {
        margin-top: 61px;
        margin-bottom: 16px;
      }

      @media (min-width: 768px) {
        justify-content: center;
      }

      @media (max-width: 767px) {
        margin-top: 76px;
      }

      .v-btn {
        width: 250px;
        height: 60px;
        transition: background ease 0.3s;
        margin-bottom: 30px;

        @media (min-width: 768px) and (max-width: 1089px) {
          margin-bottom: 23px;
        }

        @media (min-width: 768px) and (max-width: 1089px) {
          margin-right: auto !important;
          margin-left: auto !important;
        }
        @media (max-width: 568px) {
          width: 100% !important;
        }
      }

      ::v-deep .v-popover {
        @media (min-width: 1089px) {
          max-width: 250px;
          width: 100%;
        }
        @media (max-width: 767px) {
          width: 100%;
          .trigger {
            width: 100% !important;
          }
        }
      }

      .black {
        width: 250px;
        height: 60px;

        background: var(--kern-blue);
        border-radius: 6px;

        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        text-transform: uppercase;

        color: #ffffff;

        margin-right: 33px;

        @media (max-width: 767px) {
          margin-right: 0;
          width: 100% !important;
        }

        &:nth-of-type(3) {
          margin-right: 0;
        }

        &.about {
          margin-bottom: 0;
        }
        a {
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          line-height: 26px;
          text-transform: uppercase;
          text-decoration: none;
          color: #f2f2f6;
        }

        .popup {
          background: #f2f2f6;
        }
      }
    }
  }

  &__divider {
    border-bottom: 1px solid #d4d4d4;
    margin: 50px 0;
  }

  &__title-secondary {
    display: inline-block;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    color: var(--kern-blue);
  }

  &__file-info-wrapper {
    display: flex;
    align-items: center;
    width: fit-content;
  }

  &__file-icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    margin-right: 10px;
    background-size: contain;

    &--txt {
      background-image: url('../assets/file_txt.svg');
    }
    &--doc {
      background-image: url('../assets/file_doc.svg');
    }
  }

  &__file-name {
    color: var(--kern-blue);
    font-size: 18px;
  }
}
</style>

<style lang="scss">
.popover {
  margin-top: -16px;
  border-radius: 5px;

  @media (min-width: 1090px) {
    left: -16px !important;
  }
}

.tooltip-about {
  background-color: red;

  .tooltip-html__about {
    background-color: red;
  }
}
.tooltip {
  display: block !important;
  z-index: 20000;
  width: 250px;
  height: 151px;
  background: #f2f2f6;

  border-radius: 5px;
  top: 18px !important;

  @media (max-width: 568px) {
    width: 206px;
  }

  @media (max-width: 320px) {
    left: -20px !important;
  }

  .tooltip-inner {
    padding: 0;
    background: #f2f2f6;
    border-radius: 5px;

    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;

    display: flex;
    align-items: center;

    color: #8a8a8a;

    @media (max-width: 320px) {
      align-items: left;
      font-size: 14px;
      line-height: 19px;
      max-width: 217.44px;
      width: 100%;
    }
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 16px;
    border-color: #f2f2f6;
    z-index: 1;

    @media (max-width: 320px) {
      left: 172px !important;
    }
  }

  &[x-placement^='bottom'] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 10px 5px 10px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -20px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
      left: 10px !important;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}
.tooltip-html {
  margin: 37px 29px 31px;
  font-family: Karla;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;

  color: #8a8a8a;

  hr {
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin: 21px 0 20px;
  }

  .custom-button {
    margin: 0;
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;
    color: #8a8a8a;
    text-decoration: none;
  }

  &__price,
  &__about {
    padding: 10px;
  }
}
</style>
